import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import { useTranslation } from "react-i18next";
import PlainHeaderWithVideo from "components/ui/base/PlainHeaderWithVideo";
import Awards from "components/ui/extended/Awards";
import TestimonialWithImageLaurentiu from "components/ui/extended/TestimonialWithImageLaurentiu";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";

interface IndexPageProps {
  location: Location;
}

export default ({ location }: IndexPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper
      withLayout
      location={location}
      layoutSettings={{
        withMenu: false,
        withFooter: false,
        clickableLogo: false,
        withCta: false,
        mobileCta: false,
      }}
    >
      <SEO
        title={t("pages.live-demo.ai.title")}
        description={t("pages.live-demo.ai.description")}
        withoutTitleSuffix={true}
        image={{
          relativePath:
            "meta/customerly-behavioural-chats-emails-and-surveys-saas.jpg",
          alt: "Customer Operating System for SaaS",
        }}
      />

      <PlainHeaderWithVideo
        title={t("pages.live-demo.ai.title")}
        description={t("pages.live-demo.ai.description")}
        ctaRegister={{ show: true }}
        wistia={{
          id: "ujmw5qslyd",
        }}
      />

      <TestimonialWithImageLaurentiu />
      <Awards />
      <RegisterForCustomerService />
    </GlobalWrapper>
  );
};
